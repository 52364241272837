$('[data-toggle="popover"]').popover();

$.extend($.fn.bootstrapTable.defaults, {
    search: true,
    showColumns: true,
    showExport: true,
    exportTypes: ['csv'],
    icons: {
        export: 'icon-download',
        columns: 'icon-equalizer'
    },
    reorderableColumns: true
});

$(document).ready(function () {
    refresher.init();
    refresher.start_timer(refresher.get_refresh_seconds(), '#refresh_timer');

    $('body').on('click', '.js-drilldown-btn, .js-task-drilldown-btn', function () {
        $('#drilldownModalContent').html('Loading...');
        $('#drilldownModal').modal('show');

        var data = {
            type: $(this).data('type'),
            color: $(this).data('color'),
            id: $(this).data('id'),
            due_date: $(this).data('date'),
            date_from: $(this).data('date_from'),
            date_to: $(this).data('date_to'),
            _token: csrf()
        }

        var url = ($(this).hasClass('js-drilldown-btn')) ?'/affinity/tickets/drilldown':'/affinity/tasks/drilldown';

        $.post(url, data, function (response) {
            console.log(response);
            if (response.success == true) {
                $('#drilldownModalTitle').html(response.title);
                $('#drilldownModalContent').html(response.html);
                $('#tableDrilldown').bootstrapTable();
            }
        });
    });

    $('body').on('click', '.js-view-tardiness, .js-view-task-tardiness', function (e) {
        e.preventDefault();

        $('#drilldownTableContainer').html('Loading...');

        $('.js-view-tardiness-container').removeClass('danger');

        var data = {
            _token: csrf(),
            type: $(this).data('type'),
            id: $(this).data('id'),
            tardiness: $(this).data('tardiness'),
        }

        console.log(data);

        $(this).closest('.js-view-tardiness-container').addClass('danger');

        var url = ($(this).hasClass('js-view-tardiness')) ?'/affinity/tickets/tardiness-drilldown':'/affinity/tasks/tardiness-drilldown';

        $.post(url, data, function (response) {
            console.log(response);
            if (response.success == true) {
                $('#drilldownTableContainer').html(response.html);
                $('#tableDrilldown').bootstrapTable();
            }
        });
    });


    $('body').on('click', '.js-detailed-drilldown-btn, .js-detailed-task-drilldown-btn', function (e) {
        e.preventDefault();
        $('#drilldownModalContent').html('Loading...');
        $('#drilldownModal').modal('show');

        var data = {
            type: $(this).data('type'),
            color: $(this).data('color'),
            teams: $(this).data('teams'),
            categories: $(this).data('categories'),
            sub_categories: $(this).data('sub_categories'),
            users: $(this).data('users'),
            due_date: $(this).data('date'),
            date_from: $(this).data('date_from'),
            date_to: $(this).data('date_to'),
            _token: csrf()
        }

        var url = ($(this).hasClass('js-detailed-drilldown-btn')) ?'/affinity/tickets/detailed-drilldown':'/affinity/tasks/detailed-drilldown';

        $.post(url, data, function (response) {
            console.log(response);
            if (response.success == true) {
                $('#drilldownModalTitle').html(response.title);
                $('#drilldownModalContent').html(response.html);
                $('#tableDrilldown').bootstrapTable();
            }
        });
    });

    /**
     * When user selects a view from the dropdown
     */
    $('.js-select-view').change(function () {
        var selected_view_id = $(this).val();

        window.location.href = '/affinity/custom/view/' + selected_view_id;
    });

    /**
     * Opens affinity screen pop
     */
    $('body').on('click', '.js-open-affinity', function (e) {
        e.preventDefault();

        var el = $(this);

        var previous_html = el.html();

        $(this).html('Opening').removeClass('js-open-affinity');

        data = {
            _token: csrf(),
            type: $(this).attr('data-type'),
            key: 'ID',
            key_reference: $(this).attr('data-key_reference')
        }

        $.post('/affinity/notify', data, function (response) {
            el.html(previous_html).addClass('js-open-affinity');
            console.log(response);
        });
    });
});