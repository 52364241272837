/**
 * Deals with getting the address from a post code
 */

var PostCode = {
    /** Gets the address via ajax and adds to the drop down
     * @param newPostcode - The postcode to find address
     * @param el - the id/class of the dropdown to send addresses
     */
    getAddresses: function (newPostcode, elToUpdate, elToShow, elButton) {
        var self = this;
        var data = {
            'postcode': newPostcode,
            '_token': $('meta[name="csrf_token"]').attr('content')
        };

        $.post('/connection/addresses', data, function (response) {
            if (response.error) {
                console.log('Response error');
                console.log(response);
                var messageText = 'I\'m afraid that postcode could not be found. It maybe a new postcode or TalkTalk doesn\'t have it registered. Please check that you have typed it correctly or click  \'Enter Address Manually\' to enter your address manually.';
                if (response.error.ErrorCode == 'AMWS5003') {
                    messageText = 'Sorry but we are unable to check the postcode at this time, if the problem persists please report the issue or click \'Postcode not found?\' to enter your address manually.';
                }
                $(elButton).text('Find Address');
                $('.js-enter-address-manually').show();
                alert(messageText);
            }
            else if (response.indexOf("No matching address found") === -1 && response.constructor !== String) {
                console.log('Response OK');
                $(elToUpdate).html('<option value="">Please select an address</option>'); //clear the drop down list
                $.each(response, function (i, item) {
                    postcode = ('PostCode' in item) ? item.PostCode : '';
                    sub_building = ('SubBuilding' in item) ? item.SubBuilding : '';
                    building_number = ('BuildingNumber' in item) ? item.BuildingNumber : '';
                    building_name = ('BuildingName' in item) ? item.BuildingName : '';
                    street = ('Street' in item) ? item.Street : '';
                    locality = ('Locality' in item) ? item.Locality : '';
                    post_town = ('PostTown' in item) ? item.PostTown : '';
                    county = ('County' in item) ? item.County : '';
                    organisation_name = ('OrganisationName' in item) ? item.OrganisationName : '';
                    qualifier = ('Qualifier' in item) ? item.Qualifier : '';
                    exchange_code = ('ExchangeCode' in item) ? item.ExchangeCode : '';
                    district_code = ('CSSDistrictCode' in item) ? item.CSSDistrictCode : '';
                    if('ALK' in item) {
                        gold_key = (item.ALK != '') ? item.ALK : false;
                    }else{
                        gold_key = false;
                    }

                    if(gold_key){
                        $(elToUpdate).append("<option class='addressSelect' "
                            + "data-postcode='" + postcode + "'"
                            + "data-sub_building='" + sub_building + "'"
                            + "data-building_number='" + building_number + "'"
                            + "data-building_name='" + building_name + "'"
                            + "data-street='" + street + "'"
                            + "data-locality='" + locality + "'"
                            + "data-post_town='" + post_town + "'"
                            + "data-county='" + county + "'"
                            + "data-organisation_name='" + organisation_name + "'"
                            + "data-qualifier='" + qualifier + "'"
                            + "data-exchange_code='" + exchange_code + "'"
                            + "data-district_code='" + district_code + "'"
                            + "data-key='" + gold_key + "'"
                            + ">"
                            + item.DropdownAddress
                            + "</option>");
                        $(elToShow).removeClass('hidden');
                        $(elButton).text('Find Address');
                    }else{
                        console.log('No Gold Key');
                        console.log(item);
                    }
                });
            }
        });
    },

    /**
     * Returns the address lines as object
     * @param building_name
     * @param building_number
     * @param sub_number
     * @param street
     * @param locality
     * @returns {{address_line_1: *, address_line_2: *, address_line_3: *}}
     * @constructor
     */
    addressLines: function (building_name, building_number, sub_number, street, locality) {
        var address_line_1 = '';
        var address_line_2;
        var address_line_3;

        //deal with building name
        if (building_name != '') address_line_1 = building_name;
        if (sub_number != '' && building_name != '') address_line_1 = sub_number + ' ' + building_name;

         if (building_number != '') {
             var first_line = (sub_number != '') ? sub_number + ', ': '';
            //fill in second line if first is full with building name
            if (address_line_1 != '') {
                address_line_2 = first_line + building_number + ', ' + street;
            } else {
                address_line_1 = first_line + building_number + ', ' + street;
            }
        } else {
            //if no house number first line must be house name, so line 2 is street without number
            address_line_2 = street;
        }

        if (locality != '') {
            //fill in third line if second is full with street address
            if (address_line_2 != '') {
                address_line_3 = locality;
            } else {
                address_line_2 = locality;
            }
        }


        return {
            address_line_1: address_line_1,
            address_line_2: address_line_2,
            address_line_3: address_line_3
        }
    }
}
