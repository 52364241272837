var Connection = {
    /**
     * Get the line/broadband/fibre connection details via v2 API
     * addressData - The id of the address sent via the previous postcode API call
     * redirect_el - element ID of the form to submit on success
     */
    getLinePlantDetails: function (addressData, redirect_el) {
        var self = this;
        $.post('/connection/check-line', addressData, function (data) {
            console.log(data);
            self.updateFormFields(data);
            if (redirect_el) $(redirect_el).submit();
        });
    },


    /**
     * Updates the form fields with the provided bb/fibre details
     */
    updateFormFields: function (data) {
        $('#connection_details').val(JSON.stringify(data));
    }
}