var affinity_view = {
    edited: false,

    user_authorised_to_edit: false,

    /**
     * When an edit has been made, enter edit mode to change some behaviour
     */
    enterEditMode: function () {
        if (affinity_view.user_authorised_to_edit) {
            affinity_view.edited = true;
            refresher.set_suspend_refresh();
        }
    },

    /**
     * When an edit has been saved, exit edit mode to change some behaviour
     */
    exitEditMode: function () {
        affinity_view.edited = false;
    },

    /**
     * Get the data of all the panels ready for saving
     */
    getPanelData: function () {
        var panels = [];
        $('.js-panel-container').each(function () {
            var attr = {
                teams: $(this).attr('data-teams'),
                categories: $(this).attr('data-categories'),
                sub_categories: $(this).attr('data-sub_categories'),
                task_categories: $(this).attr('data-task_categories'),
                users: $(this).attr('data-users'),
                panel_title: $(this).attr('data-panel_title'),
                panel_type: $(this).attr('data-panel_type')
            }

            panels.push(attr);
        });

        console.log(panels);

        return panels;
    },

    canUserEditView: function (view_id) {
        $.get('/affinity/custom/edit-authorisation/' + view_id, function (response) {
            console.log(response);
            affinity_view.user_authorised_to_edit = response.authorised;

            window.onbeforeunload = function (event) {
                if (affinity_view.edited) return confirm("Confirm refresh");
            };
        });
    },

    /**
     * Cheecks to make sure view is valid for saving
     * @param view_title
     * @returns {boolean}
     */
    check_valid_view: function (view_title) {
        if (view_title == '') {
            alert('Please give the view a title');
            return false;
        }
        return true;
    },
     get_form_data: function(){
        var teams = [];
        var categories = [];
        var sub_categories = [];
        var task_categories = [];
        var users = [];

        $('.js-check-team:checked').each(function(){
            teams.push(parseInt($(this).attr('data-value')));
        });
        $('.js-check-category:checked').each(function(){
            categories.push($(this).attr('data-value'));
        });
        $('.js-check-sub-category:checked').each(function(){
            sub_categories.push($(this).attr('data-value'));
        });
         $('.js-check-task-category:checked').each(function(){
             task_categories.push($(this).attr('data-value'));
         });
        $('.js-check-user:checked').each(function(){
            users.push(parseInt($(this).attr('data-value')));
        });

        return {
            teams: teams.length != 0 ? teams : 'all',
            categories: categories.length != 0 ? categories : 'all',
            sub_categories: sub_categories.length != 0 ? sub_categories : 'all',
            task_categories: task_categories.length != 0 ? task_categories : 'all',
            users: users.length != 0 ? users : 'all',
            panel_title: $('#panel_title').val(),
            panel_type: $('#panel_type').val(),
            _token: csrf()
        }
    }
}


$(document).ready(function () {
    var selected_panel;

    /**
     * Remove the panel
     */
    $('body').on('click', '.js-delete-panel', function () {
        affinity_view.enterEditMode();
        $(this).closest('.js-panel-container').remove();
    });

    $('.js-insert-panel').click(function () {
        $('#panel_title').val('');
        $('.js-check-team, .js-check-category, .js-check-sub-category, .js-check-user').prop('checked', false);
        $('.js-add-to-view').show();
        $('.js-update-to-view').hide();
    });

    $('.js-add-to-view').click(function () {
        affinity_view.enterEditMode();
        refresher.set_suspend_refresh();

        data = affinity_view.get_form_data();

        console.log(data);

            $.post('/affinity/custom/panel', data, function (response) {
                console.log(response);
                $('.js-panels-container').append(response.html);
                $('#editPanelModal').modal('hide');
            });
    });

    $('.js-update-to-view').click(function () {
        affinity_view.enterEditMode();
        refresher.set_suspend_refresh();

        data = affinity_view.get_form_data();

        console.log(data);

            $.post('/affinity/custom/panel', data, function (response) {
                console.log(response);
                selected_panel.replaceWith(response.html);
                $('#editPanelModal').modal('hide');
            });
    });

    /**
     * When user requests to save the view
     */
    $('.js-save-view').click(function () {
        if (affinity_view.check_valid_view($('.js-view_title').val())) {
            var panels = affinity_view.getPanelData();

            var data = {
                title: $('.js-view_title').val(),
                view_id: $('.js-view_id').val(),
                status: $("#status option:selected").val(),
                view_object: JSON.stringify(panels),
                _token: csrf()
            }

            $.post('/affinity/custom/save-view', data, function (response) {
                console.log(response);

                if (response.success == true) {
                    //this updates the view id in form, incase id has changes ie: new view
                    $('.js-view_id').val(response.affinity_view.id);

                    $('#notice-title').html('View saved Successfully');

                    $('#notice-content').html('Congratulations, your statistics view has been saved successfully');

                    $('#noticeModal').modal('show');

                    affinity_view.exitEditMode();
                } else {
                    $('#notice-title').html('Error');

                    $('#notice-content').html('View was not saved :( because '.response.error);

                    $('#noticeModal').modal('show');
                }
            });
        }
    });

    /**
     * When user requests to copy the view
     */
    $('.js-copy-view').click(function () {
        var panels = affinity_view.getPanelData();

        var data = {
            title: $('.js-view_title').val() + ' Copy:' + new Date(),
            view_id: null,
            status: $("#status option:selected").val(),
            view_object: JSON.stringify(panels),
            _token: csrf()
        }

        $.post('/affinity/custom/save-view', data, function (response) {
            console.log(response);

            if (response.success == true) {
                alert('You have now made a copy of this view, you will now be relocated to that view where you can make edits');

                window.location.href = '/affinity/custom/view/' + response.affinity_view.id;
            } else {
                $('#notice-title').html('Error');

                $('#notice-content').html('View was not saved :( because '.response.error);

                $('#noticeModal').modal('show');
            }
        });
    });

    /**
     * When users clicks to edit a panel
     */
    $('body').on('click', '.js-edit-panel', function () {
        selected_panel = $(this).closest('.js-panel-container');
        var teams = JSON.parse(selected_panel.attr('data-teams'));
        var categories = JSON.parse(selected_panel.attr('data-categories'));
        var sub_categories = JSON.parse(selected_panel.attr('data-sub_categories'));
        var users = JSON.parse(selected_panel.attr('data-users'));

        $('#panel_title').val(selected_panel.attr('data-panel_title'));
        $('#panel_type').val(selected_panel.attr('data-panel_type'));

        $('.js-check-team').each(function(){
            if(teams.indexOf($(this).attr('data-value')) > -1 && $(this).attr('data-value') != ''){$(this).prop('checked', true);}else{$(this).prop('checked', false);}
        });

        $('.js-check-category').each(function(){
            if(categories.indexOf($(this).attr('data-value')) > -1 && $(this).attr('data-value') != ''){$(this).prop('checked', true);}else{$(this).prop('checked', false);}
        });

        $('.js-check-sub-category').each(function(){
            if(sub_categories.indexOf($(this).attr('data-value')) > -1 && $(this).attr('data-value') != ''){$(this).prop('checked', true);}else{$(this).prop('checked', false);}
        });

        $('.js-check-user').each(function(){
            if(users.indexOf($(this).attr('data-value')) > -1 && $(this).attr('data-value') != ''){$(this).prop('checked', true);}else{$(this).prop('checked', false);}
        });

        $('.js-add-to-view').hide();
        $('.js-update-to-view').show();
    });

    /**
     * When user request to show/hide edit form
     */
    $('.js-edit-view, .js-create-view').click(function () {
        $('.js-edit-container').toggle();
        $('.js-hide-on-create').toggle();
        refresher.set_suspend_refresh();

        var sort_container = $('#sort_container');
        if(sort_container.length != 0){
            Sortable.create(document.getElementById('sort_container'), {
                animation: 300,
                onStart: function (evt) {
                    affinity_view.enterEditMode();
                }
            });
        }
    });
});

